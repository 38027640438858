<template lang="pug">
.card
  slot
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.card {
  @apply border-2 rounded-lg border-green-300 bg-white;
  box-shadow: 0 0px 15px -5px rgba(132, 204, 22, 0.2),
    0 5px 10px -5px rgba(132, 204, 22, 0.1);
}
</style>