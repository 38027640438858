<template lang="pug">
.header 
  slot
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.header {
  @apply text-center font-semibold pb-4 text-green-700;
}
</style>