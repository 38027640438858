<template lang="pug">
Card.card-measures
  .header Cambios estimados de tu cuerpo
  .block-image
    img(:src="getImageGender")
    .wrapper-measure-boy
      .line 8%
      .line 25%
      .line 15%
      .line 11%
</template>
<script>
import Card from "@/components/analysis/card";

export default {
  components: {
    Card,
  },
  props: {
    gender: {
      type: String,
      required: true,
    },
  },
  computed: {
    getImageGender() {
      if (this.gender == "female") return require(`@/assets/changes_woman.svg`);
      else return require(`@/assets/changes_man.svg`);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-measures {
  @apply p-4 relative h-full;
  .header {
    @apply text-center font-semibold pb-4 text-green-700;
  }
  .block-image {
    @apply relative px-4;
    img {
      width: 100px;
    }

    .line {
      width: 100%;
      height: 100%;
      @apply flex items-center justify-end font-medium;

      & + .line {
        @apply border-t-2 border-dashed border-blue-200;
      }
    }

    .wrapper-measure-boy {
      @apply absolute w-full h-full top-0 left-0 flex flex-col justify-between py-4 px-4;
    }
  }
}
</style>