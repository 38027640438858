<template lang='pug'>
.w-full.h-full
  .group-macro
    .text-sm.font-medium.text-green-700 Proteina
    .w-full.h-5.rounded-full.border.border-red-300.bg-red-100.relative.overflow-hidden
      .bg-red-300.absolute.h-full.rounded-full.-ml-1(style="width: 30%")
      .flex.h-full.items-center.relative
        .cuantity.text-xs.p-0.m-0.leading-none.ml-auto.mr-2.text-green-700.font-medium {{ proteins }}gr

  .group-macro.mt-2
    .text-sm.font-medium.text-green-700 Grasas saludables
    .w-full.h-5.rounded-full.border.border-green-400.bg-green-50.relative.overflow-hidden
      .bg-green-200.absolute.h-full.rounded-full.-ml-1(style="width: 75%")
      .flex.h-full.items-center
        .cuantity.text-xs.p-0.m-0.leading-none.ml-auto.mr-2.text-green-700.font-medium {{ grease }}gr

  .group-macro.mt-2
    .text-sm.font-medium.text-green-700 Carbohidratos
    .w-full.h-5.rounded-full.border.border-yellow-400.bg-yellow-50.relative.overflow-hidden
      .bg-yellow-200.absolute.h-full.rounded-full.-ml-1(style="width: 10%")
      .flex.h-full.items-center
        .cuantity.text-xs.p-0.m-0.leading-none.ml-auto.mr-2.text-green-700.font-medium {{ carbohydrates }}gr
</template>
<script>
export default {
  props: {
    proteins: {
      type: Number,
      required: true,
    },
    grease: {
      type: Number,
      required: true,
    },
    carbohydrates: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.cuantity {
  z-index: 10;
}
</style>