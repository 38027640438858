<template lang="pug">
Card.card-chart
  .header {{ WeightLastWeek }}
  .chart
    .flex.h-full
      .box(v-for="(item, index) in WeightWeeks", :key="index")
        .box-size {{ item.weight }}
        .pointer(:class="`point-${index + 1}`")
        .text Semana {{ index + 1 }}
</template>
<script>
import Card from "@/components/analysis/card";

export default {
  components: {
    Card,
  },
  props: {
    WeightAfterMonth: {
      type: Object,
      required: true,
    },
    metric: {
      // ['kg', lb]
      type: String,
      required: true,
    },
  },
  computed: {
    WeightLastWeek() {
      const lastWeek = this.WeightAfterMonth.arrayWeeks[3];
      const d = new Date(lastWeek.date);
      const date =
        "Antes del " +
        d.getDate() +
        " de " +
        d.getMonth() +
        " del " +
        d.getFullYear() +
        " pesaras " +
        lastWeek.weight +
        this.metric;
      return date;
    },
    WeightWeeks() {
      return this.WeightAfterMonth.arrayWeeks.map((w) => {
        return {
          weight: `${w.weight} ${this.metric}`,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-chart {
  @apply relative h-full;
  overflow: hidden;
  .header {
    @apply text-center font-semibold p-4 pb-6 text-green-700;
  }

  .chart {
    background-image: url(~@/assets/line_chart.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 240px;
  }

  .box {
    width: 100%;
    @apply flex justify-center h-full;
    &::before {
      content: "";
      width: 3px;
      @apply bg-green-100 absolute z-0 h-full;
    }
    .text {
      @apply bg-white text-xs font-bold mb-4 text-green-700 absolute bottom-0;
    }

    .box-size {
      @apply absolute bg-white px-2 py-1 text-sm font-bold rounded-md border-2 -mt-2 text-blue-700;
    }

    .pointer {
      margin-left: -1px;
      @apply w-6 h-6 rounded-full bg-white border-4 border-green-500 z-10;
      &.point-1 {
        margin-top: 17%;
      }
      &.point-2 {
        margin-top: 33%;
      }
      &.point-3 {
        margin-top: 68%;
      }
      &.point-4 {
        margin-top: 89%;
      }
    }
  }
}
</style>