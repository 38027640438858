<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 600 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M604.5 270.557C240 270.557 344.5 62.0568 18 66.0568V301H604.5V270.557Z"
      fill="url(#paint0_linear)"
      fill-opacity="0.56"
    />
    <g filter="url(#filter0_d)">
      <path
        d="M18 66.0568C344.5 62.0568 240 270.557 604.5 270.557"
        stroke="url(#paint1_linear)"
        stroke-width="12"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <g filter="url(#filter1_d)">
      <circle cx="45" cy="66" r="9" fill="#C2EC69" />
      <circle cx="45" cy="66" r="8" stroke="white" stroke-width="2" />
    </g>
    <g filter="url(#filter2_d)">
      <circle cx="224" cy="110" r="9" fill="#C2EC69" />
      <circle cx="224" cy="110" r="8" stroke="white" stroke-width="2" />
    </g>
    <g filter="url(#filter3_d)">
      <circle cx="400" cy="235" r="9" fill="#C2EC69" />
      <circle cx="400" cy="235" r="8" stroke="white" stroke-width="2" />
    </g>
    <g filter="url(#filter4_d)">
      <circle cx="579" cy="270" r="9" fill="#C2EC69" />
      <circle cx="579" cy="270" r="8" stroke="white" stroke-width="2" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="50"
        width="622.5"
        height="240.557"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="28"
        y="51"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d"
        x="207"
        y="95"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d"
        x="383"
        y="220"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d"
        x="562"
        y="255"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="311.25"
        y1="66"
        x2="311.25"
        y2="301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EC8169" stop-opacity="0.5" />
        <stop offset="0.875" stop-color="#93DF60" stop-opacity="0.17" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="311.25"
        y1="66"
        x2="311.25"
        y2="270.557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E1665E" />
        <stop offset="1" stop-color="#91E160" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
</style>