<template lang='pug'>
.w-full.h-full.flex.justify-center.items-center.relative
  .border-2.border-green-600.font-bold.text-white.text-xl.flex.items-center.justify-center.w-24.h-24.bg-green-300.rounded-full.absolute.top-0.-mt-2 75%
  .border-2.border-red-600.font-bold.text-white.text-xl.flex.items-center.justify-center.w-20.h-20.bg-red-300.rounded-full.absolute.z-10.bottom-0.mb-8.left-6 20%
  .border-2.border-yellow-600.font-bold.text-white.text-xl.flex.items-center.justify-center.w-16.h-16.bg-yellow-300.rounded-full.absolute.z-20.bottom-0.mb-8.right-8 5%
  .text.mt-4.text-sm.font-medium.text-green-700.self-end.z-50 Calorias:
    span.font-bold.pl-2.text-blue-600.text-lg {{ calories }}
</template>
<script>
export default {
  props: {
    calories: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
</style>