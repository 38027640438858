<template lang="pug">
card.content-keto-bmi
  p.text-sm Según tus respuestas, tienes:
  h3 {{ typeBMI }}
  h4.text-sm {{ BMI }} IMC
</template>
<script>
import Card from "@/components/analysis/card";

export default {
  components: {
    Card,
  },
  props: {
    BMI: {
      type: Number,
      default: 0,
    },
    BMICategory: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    typeBMI() {
      if (this.BMICategory == 3) return "Obesidad peligrosa";
      if (this.BMICategory == 2) return "Obesidad moderada";
      if (this.BMICategory == 1) return "Peso saludable";
      if (this.BMICategory == 0) return "Por debajo de lo normal";
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.content-keto-bmi {
  @apply overflow-hidden h-full text-center p-2 flex flex-col items-center justify-center;

  h3 {
    @apply font-bold text-red-700;
  }

  h4 {
    @apply font-semibold text-yellow-500;
  }
}
</style>