<template lang="pug">
.background
.container.pt-36.pb-24
  .flex.justify-center
    .header.text-center
      h1.title Tu dieta personalizada <span>keto</span> está lista
      p.mt-8 A continuación te mostramos los resultados que obtendrás en base a las respuestas que nos has facilitado. Hemos hecho los calculos para que consigas tu objetivo con nuestro plan nutricional en la fecha indicada en el gráfico de más abajo.
      .flex.justify-center.mt-8
        router-link(:to="{ name: 'Quiz_email' }")
          CtaButton(textButton="Obtener Ahora")
  section
    .grid.grid-cols-6.gap-8.mt-24
      .col-span-2 
        BlockImc(:BMI="BMI", :BMICategory="BMICategory")
      .col-span-4
        Card.overflow-hidden
          .content-keto-loss
            p Personas similares perdieron más de 30 kg con Keto
            .box-percent 87%
    .grid.grid-cols-6.gap-8.mt-12
      .col-span-4
        BlockChart(
          :WeightAfterMonth="WeightAfterMonth",
          :metric="WeightMetric"
        )
      .col-span-2
        BlockMeasures(:gender="Gender")
    .grid.grid-cols-12.gap-8.mt-12
      .col-span-3
        Card.p-4.h-full
          .flex.flex-col.items-center.justify-center.h-full
            Header Edad metabolica
            .flex.flex-col.items-center.justify-center.h-full
              .text.text-8xl.font-bold {{ MetabolicAge }}
              .text.mt-4.font-semibold.pl-2.text-xl Años

      .col-span-6
        Card.p-4.h-full
          .flex.flex-col.h-full
            Header Ingesta diaria de calorías
            .grid.grid-cols-2.divide-x-2.divide-green-100.h-full
              BlockChartsMacros.pr-4(
                :proteins="Macros.protein",
                :grease="Macros.fat",
                :carbohydrates="Macros.carb"
              )
              BlockChartsCalories.pl-2(
                :calories="CaloriesNeed.heavyWeightLoss"
              )

      .col-span-3
        Card.p-4.h-full
          Header Ingesta diaria de agua
          .flex.flex-col.items-center.justify-center
            .icon-glass
            .text.mt-4.text-sm.font-medium.text-green-700 Agua recomendada:
              span.font-bold.pl-2.text-blue-600.text-lg {{ WaterNeed }}L
KetoBrandsComponent
KetoDoctorPromise.mt-24
.flex.justify-center.mt-8.mb-16
  router-link(:to="{ name: 'Quiz_email' }")
    ctaButton(textButton="Obtener Ahora")
</template>
<script>
import Card from "@/components/analysis/card";
import Header from "@/components/analysis/header.card";

import ChartSvg from "@/components/analysis/chart2.svg";

import BlockImc from "@/components/analysis/block.imc";
import BlockChart from "@/components/analysis/block.chart";
import BlockMeasures from "@/components/analysis/block.measures";
import BlockChartsCalories from "@/components/analysis/block.chart-calories";
import BlockChartsMacros from "@/components/analysis/block.chart-macros";

import KetoBrandsComponent from "@/components/shared/keto.brands";
import KetoDoctorPromise from "@/components/shared/doctor.promise";

export default {
  components: {
    Card,
    Header,
    ChartSvg,
    BlockImc,
    BlockChart,
    BlockMeasures,
    KetoBrandsComponent,
    KetoDoctorPromise,
    BlockChartsCalories,
    BlockChartsMacros,
  },

  computed: {
    BMR() {
      const bmr = this.$store.getters["analysisStore/getBMR"];
      return bmr;
    },
    BMI() {
      const bmi = this.$store.getters["analysisStore/getBMI"];
      return bmi;
    },
    IdealBodyWeight() {
      const ideal = this.$store.getters["analysisStore/getIdealBodyWeight"];
      return ideal;
    },
    CaloriesNeed() {
      const calories = this.$store.getters["analysisStore/getCalorieNeeds"];
      return calories;
    },
    Macros() {
      const macros = this.$store.getters["analysisStore/getMacros"];
      return macros;
    },

    WeightAfterMonth() {
      const arrayWeeks = this.$store.getters["analysisStore/weightAfterMonth"];
      return arrayWeeks;
    },

    BMICategory() {
      const bmiCategory = this.$store.getters["analysisStore/getBMICategory"];
      return bmiCategory;
    },
    MetabolicAge() {
      const metabolicAge = this.$store.getters["analysisStore/getMetabolicAge"];
      return metabolicAge;
    },
    WeightMetric() {
      console.log(this.$store.getters["analysisStore/getMetric"]);
      return this.$store.getters["analysisStore/getMetric"] == "metric"
        ? "kg"
        : "lb";
    },
    WaterNeed() {
      return this.$store.getters["analysisStore/getWaterNeed"];
    },
    Gender() {
      return this.$store.getters["analysisStore/getGender"];
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  p {
    @apply text-lg font-medium;
  }
}

.m-item-summary__graph-8 {
  position: relative;
  width: 100%;
  height: 350px;
}

.content-keto-loss {
  @apply flex justify-between items-center;
  p {
    @apply px-4 text-lg font-medium text-green-700;
  }

  .box-percent {
    @apply bg-green-500 text-white font-black text-5xl py-4 px-4 rounded-md my-1 mr-1;
  }
}

.icon-glass {
  background-image: url(~@/assets/glass_2.svg);
  width: 100px;
  height: 100px;
}
</style>